import { RadioGroup } from "@headlessui/react";
import { classNames } from "utils";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import lodash from "lodash";

interface RadioOption {
  label: string;
  value: string;
}

export interface RadioGroupInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  options: RadioOption[];
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  parentClassName?: string;
  optionsContainerClassName?: string;
}

export default function RadioGroupInput({
  id,
  options,
  values,
  setFieldValue,
  setFieldTouched,
  label,
  errors,
  touched,
  labelHidden,
  disabled,
  parentClassName = "",
  optionsContainerClassName = ""
}: RadioGroupInputProps) {

  return (
    <RadioGroup
      disabled={disabled}
      value={lodash.get(values, id, null)}
      onChange={(val) => {
        setFieldTouched?.(id, true);
        setFieldValue(id, val);
      }}
      className={parentClassName}
    >
      {!labelHidden && (
        <RadioGroup.Label className='text-base font-semibold leading-6 text-gray-900'>
          {label ?? "Select an option"}
        </RadioGroup.Label>
      )}

      <div className={classNames(
        'mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-4',
        optionsContainerClassName
      )}>
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={({ active }) =>
              classNames(
                active
                  ? "border-primary-600 ring-2 ring-primary-600"
                  : "border-gray-300",
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                    <span className='flex flex-1'>
                      <span className='flex flex-col'>
                        <RadioGroup.Label
                          as='span'
                          className='block text-sm font-medium text-gray-900'
                        >
                          {option.label}
                        </RadioGroup.Label>
                      </span>
                    </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-primary-600"
                  )}
                  aria-hidden='true'
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-primary-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
        {lodash.get(errors, id) && lodash.get(touched, id) ? (
          <p className='mt-2 text-sm text-red-600' id={`${id}-error`}>
            {lodash.get(errors, id)}
          </p>
        ) : null}
      </div>
    </RadioGroup>
  )

}