import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import lodash from "lodash";

interface ServiceOrderExportViewProps {
  serviceOrderExport: {
    status: string;
    type: string;
    meta: any;
    _id: string;
    code: string;
    description: string;
    region: {
      _id: string;
      code: string;
      name: string;
    };
    district: {
      _id: string;
      code: string;
      name: string;
    };
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    params: {
      args: any;
      ctx: any;
    };
    startedAt: string;
    completedAt: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const ServiceOrderExportView: FC<ServiceOrderExportViewProps> = ({
                                                                          serviceOrderExport,
                                                                        }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200 overflow-y-auto">
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Export Information</span>
          <div className="grid grid-cols-2 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.status || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Type
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.type ? lodash.startCase(serviceOrderExport.type) : "N/A"}
              </div>
            </div>
            <div className="col-start-1 col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Description
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.description || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Region
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.region?.name || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                District
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.district?.name || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Created By
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[
                  (serviceOrderExport?.createdBy?.firstName || "")?.trim(),
                  (serviceOrderExport?.createdBy?.lastName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Created At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(serviceOrderExport?.createdAt).format(
                  "DD/MM/YYYY [at] hh:mm A",
                )}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Started At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.startedAt
                  ? moment(serviceOrderExport?.startedAt).format(
                    "DD/MM/YYYY [at] hh:mm A",
                  )
                  : "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Completed At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.completedAt
                  ? moment(serviceOrderExport?.completedAt).format(
                    "DD/MM/YYYY [at] hh:mm A",
                  )
                  : "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Progress
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                <div className="py-1" aria-hidden="true">
                  <div className="overflow-hidden rounded-full bg-gray-200 w-full">
                    <div
                      className="h-2 rounded-full bg-primary-600"
                      style={{
                        width: numeral(
                          serviceOrderExport?.meta?.exportedCount /
                          serviceOrderExport?.meta?.totalDataCount,
                        ).format("0.00%"),
                      }}
                    ></div>
                  </div>
                </div>
                <div className="hidden grid-cols-2 text-sm text-gray-500 sm:grid">
                  <div className="">
                    {numeral(serviceOrderExport?.meta?.exportedCount).format(
                      "0,0",
                    )}
                    /
                    {numeral(serviceOrderExport?.meta?.totalDataCount).format(
                      "0,0",
                    )}
                  </div>
                  <div className="text-right">
                    {numeral(
                      serviceOrderExport?.meta?.exportedCount /
                      serviceOrderExport?.meta?.totalDataCount,
                    ).format("0.00%")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Filter Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {lodash.startCase(serviceOrderExport?.params?.args?.status) ||
                  "All"}
              </div>
            </div>

            <div className="">
              <span className="block text-sm font-light text-gray-700">
                From Date
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.params?.args?.fromDate
                  ? moment(serviceOrderExport?.params?.args?.fromDate).format(
                    "DD/MM/YYYY"
                  )
                  : "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                To Date
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {serviceOrderExport?.params?.args?.toDate
                  ? moment(serviceOrderExport?.params?.args?.toDate).format(
                    "DD/MM/YYYY"
                  )
                  : "N/A"}
              </div>
            </div>
            {
              !!serviceOrderExport?.params?.args?.category && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Category
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.category
                      ? lodash.startCase(serviceOrderExport?.params?.args?.category)
                      : null}
                  </div>
                </div>
              )
            }
            {
              !!serviceOrderExport?.params?.args?.type && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.type
                      ? lodash.startCase(serviceOrderExport?.params?.args?.type)
                      : null}
                  </div>
                </div>
              )
            }
            {
              !!serviceOrderExport?.params?.args?.priority && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Priority
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.priority
                      ? lodash.startCase(serviceOrderExport?.params?.args?.priority)
                      : null}
                  </div>
                </div>
              )
            }
            {
              !!serviceOrderExport?.params?.args?.result && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Result
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.result
                      ? lodash.startCase(serviceOrderExport?.params?.args?.result)
                      : null}
                  </div>
                </div>
              )
            }
            {
              !!serviceOrderExport?.params?.args?.replacementType && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Replacement Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.replacementType
                      ? lodash.startCase(serviceOrderExport?.params?.args?.replacementType)
                      : null}
                  </div>
                </div>
              )
            }
            {
              !!serviceOrderExport?.params?.args?.serviceClass && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Class
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.serviceClass
                      ? lodash.startCase(serviceOrderExport?.params?.args?.serviceClass)
                      : null}
                  </div>
                </div>
              )
            }
            {
              !!serviceOrderExport?.params?.args?.serviceType && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.serviceType
                      ? lodash.startCase(serviceOrderExport?.params?.args?.serviceType)
                      : null}
                  </div>
                </div>
              )
            }
            {
              !!serviceOrderExport?.params?.args?.installationMeterSystemSyncStatus && (
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceOrderExport?.params?.args?.installationMeterSystemSyncStatus
                      ? lodash.startCase(serviceOrderExport?.params?.args?.installationMeterSystemSyncStatus)
                      : null}
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOrderExportView
