import { useQuery } from "@apollo/client";
import { TableComponent,  } from "components/tables";
import { OfficeCalendarHeader } from "components/layouts";
import { Shimmers } from "components/tables";
import ActionButton, {Action} from "components/buttons/action-button";
import {StatusBadge, SelectInput} from "components/core";
import config from "config";
import lodash from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  useDownloadFile,
  useTableData,
  useUrlState,
  wrapClick,
  wrapOnchange
} from "utils";
import ViewServiceOrderExportContainer from "./view";
import { GET_SERVICE_ORDER_EXPORTS } from './apollo'
import {currentUserVar} from "apollo/cache/auth";
import CancelServiceOrderExportsContainer from "./cancel";
import { Application } from "apollo/data/applications";

export const ServiceOrderExportsPage: FC = () => {

  const ServiceOrderApplicationMap: Record<Application, string[]> = {
    ppme: [
      "InstallationMonitoringServiceOrders"
    ],
    ips: [
      'IllegalityConfirmationServiceOrders',
      'Invoices',
      'SuspectedIllegalities',  
    ],
    technical: [
      "InspectionServiceOrders",
      "RegularizationInspectionServiceOrders",
      "RegularizationInstallationServiceOrders",
      "InstallationServiceOrders",
      "BacklogInstallationServiceOrders",
      "ReplacementServiceOrders",
      "InvestigationServiceOrders",
      "MeterRemovalServiceOrders",
      "PrepaidMonitoringServiceOrders",
    ],
    debt: [
      "DisconnectionServiceOrders",
      "ReconnectionServiceOrders",
      "DisconnectionMonitoringServiceOrders"
    ],
    management: [
      "BacklogInstallationServiceOrders",
      "DisconnectionServiceOrder",
      "InspectionServiceOrders",
      "InstallationServiceOrders",
      "InvestigationServiceOrders",
      "MeterRemovalServiceOrders",
      "RegularizationInspectionServiceOrders",
      "RegularizationInstallationServiceOrders",
      "ReconnectionServiceOrder",
      "ReplacementServiceOrders",
      "PrepaidMonitoringServiceOrders",
      "FaultServiceOrders",
    ],
    engineer: [
      "FaultServiceOrders",
      "BoundaryInstallationServiceOrders",
      "BoundaryReplacementServiceOrders",
      "TransformerInstallationServiceOrders",
      "TransformerMaintenanceServiceOrders",
      "TransformerReplacementServiceOrders",
    ],
    mis: [
      "InstallationServiceOrders",
      "ReplacementServiceOrders"
    ]
  }


  const ServiceOrderExportTypes = ServiceOrderApplicationMap[config.apollo.name]

  const SearchFields = ["code", "description"]
  const { downloadAction } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + " Service Order Export.xlsx",
  });

  const currentUser = currentUserVar()
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal,setModal] = useUrlState("modal");
  const [type, setType] = useUrlState("type");
  const [setCreatedBy] = useUrlState("createdBy");

  const filter = useMemo(
    () => ({
      page: Number(searchParams.page || config.constants.page),
      pageSize: Number(searchParams.pageSize || config.constants.pageSize),
      search: (searchParams.search || "").toString(),
      searchFields: searchParams.searchField ? [searchParams.searchField] : SearchFields,
      sort: searchParams.sort || "",
      origin: config.apollo.name || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      type: searchParams.type || undefined,
      ...(searchParams.type ? {} : { types: ServiceOrderExportTypes }),
      createdBy: currentUser?._id
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_SERVICE_ORDER_EXPORTS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
    pollInterval: 30 * 1000 // milliseconds
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const onSearch = (searchValue: string, searchField: string) => {
    navigate({
      search: (old) => ({
        ...old,
        page: config.constants.page,
        pageSize: searchParams.pageSize || config.constants.pageSize,
        search: String(searchValue),
        searchField: searchField ? searchField : undefined,
      }),
    });
  };

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const records = useTableData(data || {});

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <OfficeCalendarHeader
        defaultView="month"
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"service order exports"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchSubmit={onSearch}
            searchOptions={SearchFields.map((field) => ({
              label: lodash.startCase(field),
              value: field
            }))}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
                <SelectInput
                  id={"type"}
                  values={{ type }}
                  handleChange={wrapOnchange(setType)}
                  handleBlur={undefined}
                  label={""}
                  options={[
                    { label: "--- Select Type ---", value: "" },
                    ...((ServiceOrderExportTypes ?? []).map((type) => ({
                      label: lodash.startCase(type),
                      value: type
                    }))),
                  ]}
                />
                <SelectInput
                  id={"createdBy"}
                  // values={{ createdBy: createdBy ?? "" }}
                  values={{ createdBy: currentUser?._id }}
                  handleChange={wrapOnchange(setCreatedBy)}
                  handleBlur={undefined}
                  disabled={true}
                  label=""
                  options={[
                    // { label: "All", value: "" },
                    ...(currentUser ? [{ label: "Created By Me", value: currentUser._id }] : []),
                  ]}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Region | District
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Progress
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Description
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Started At | Completed At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {moment(item?.createdAt).format("DD/MM/YYYY [at] hh:mm A")}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item.region ? `${item?.region?.name} (${item?.region?.code || "N/A"})` : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item.district ? `${item?.district?.name} (${item?.district?.code || "N/A"})` : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.type ? lodash.startCase(item?.type) : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="py-1" aria-hidden="true">
                    <div className="overflow-hidden rounded-full bg-gray-200 w-64">
                      <div
                        className="h-2 rounded-full bg-primary-600"
                        style={{
                          width: numeral(
                            item?.meta?.exportedCount /
                            item?.meta?.totalDataCount
                          ).format("0.00%"),
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="hidden grid-cols-2 text-sm text-gray-500 sm:grid">
                    <div className="">
                      {numeral(item?.meta?.exportedCount).format("0,0")}/
                      {numeral(item?.meta?.totalDataCount).format("0,0")}
                    </div>
                    <div className="text-right">
                      {numeral(
                        item?.meta?.exportedCount / item?.meta?.totalDataCount
                      ).format("0.00%")}
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <p className="text-ellipsis overflow-hidden max-w-[400px]">
                    {item.description}
                  </p>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <StatusBadge
                    status={item?.status}
                    options={{
                      Pending: "bg-amber-100 text-amber-800",
                      InProgress: "bg-yellow-100 text-yellow-800",
                      Completed: "bg-green-100 text-green-800"
                    }}
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.startedAt
                      ? moment(item?.startedAt).format("DD/MM/YYYY, hh:mm A")
                      : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.completedAt
                      ? moment(item?.completedAt).format("DD/MM/YYYY, hh:mm A")
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    {["Pending", "InProgress"].includes(item?.status) && (
                        <ActionButton
                          action="cancel"
                          onClick={dispatchAction(item?._id, "cancel")}
                        />
                      )}
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action="download"
                      disabled={!item?.fileUrl}
                      onClick={() => downloadAction(item?.fileUrl)}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {!!searchParams.id?.length && (
        <>
          <ViewServiceOrderExportContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <CancelServiceOrderExportsContainer
            open={modal === "cancel"}
            setOpen={(val: boolean) => setModal(val ? "cancel" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </main>
  );
};

export default ServiceOrderExportsPage;