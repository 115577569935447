import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { LocationGenerics } from "router/location";
import { DistrictPicker, RegionPicker } from "containers";
import { classNames, useUrlState } from "utils";
import lodash from "lodash";
import { FC, Fragment, useEffect } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { Header } from "components";
import CustomPicker from "./custom-picker";
import DayPicker from "./day-picker";
import MonthPicker from "./month-picker";
import QuarterPicker from "./quarter-picker";
import WeekPicker from "./week-picker";

const defaultViews = ["all-time", "day", "month", "custom"] as const;
interface OfficeHeaderProps {
  renderActions?: FC<{}>;
  views?: string[];
  defaultView?: string;
}

export const CalendarHeader: FC<OfficeHeaderProps> = ({
  renderActions,
  views = defaultViews,
  defaultView = "day",
}) => {
  const [currentView, setCurrentView] = useUrlState("view");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState<string | undefined>("district");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentView) {
      setCurrentView(defaultView);
    } else if (currentView === "all-time") {
      if (
        searchParams?.fromDate !== undefined ||
        searchParams?.toDate !== undefined
      ) {
        navigate({
          search: (old) => ({
            ...old,
            ...(old?.page ? { page: 1 } : {}),
            fromDate: undefined,
            toDate: undefined,
          }),
        });
      }
    }
    // eslint-disable-next-line
  }, [currentView, defaultView, setCurrentView]);

  return (
    <Header
      renderActions={() => (
        <div className="flex items-center">
          <div className="hidden md:ml-4 md:flex md:items-center space-x-3">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {lodash.upperFirst(currentView)} View
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-10 right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {views.map((view) => (
                      <Menu.Item key={view}>
                        {({ active }) => (
                          <Link
                            search={(search) => ({ ...search, view })}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm",
                            )}
                          >
                            {lodash.upperFirst(view)} View
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {currentView === "day" && <DayPicker />}
            {currentView === "week" && <WeekPicker />}
            {currentView === "month" && <MonthPicker />}
            {currentView === "quarter" && <QuarterPicker />}
            {currentView === "custom" && <CustomPicker />}
            <div className="h-6 w-px bg-gray-300" />
            <RegionPicker
              id="region"
              labelHidden={true}
              setFieldValue={(_field: string, value: string) => {
                setRegion(value);
                setDistrict(undefined);
              }}
              
              values={{ region }}
              rawId={true}
            />
            {region && (
              <DistrictPicker
                id="district"
                labelHidden={true}
                setFieldValue={(_field: string, value: string) =>
                  setDistrict(value)
                }
                values={{ district }}
                filter={{ region }}
                rawId={true}
              />
            )}
            {renderActions && (
              <>
                <div className="h-6 w-px bg-gray-300" />
                {renderActions?.({})}
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};

export { MonthPicker, QuarterPicker , WeekPicker, CustomPicker, DayPicker};
