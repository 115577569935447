import { ResponsivePie } from "@nivo/pie";
import lodash from "lodash";
import numeral from "numeral";
import { FC } from "react";
import { classNames } from "utils";

interface PieChartProps {
  title: string;
  loading?: boolean;
  data: {
    color: string;
    bgColor: string;
    id: string;
    label: string;
    value: number;
  }[];
}

const PieChart: FC<PieChartProps> = ({ data, title }) => {
  const total = lodash.sumBy(data, "value");

  return (
    <div className="flex flex-col">
      <h3 className="text-md font-medium leading-6 text-gray-900">{title}</h3>
      <div className="mt-3 flex flex-col overflow-hidden rounded-lg bg-white shadow">
        <div className={classNames(
            data?.length > 5 ? "grid-cols-1" : "grid-cols-2",
            "flex-1 grid my-auto gap-12 p-4  sm:p-6"
          )}>
          <div className={classNames(
            data?.length > 5 ? "grid-cols-2" : "grid-cols-1",
            "flex-1 grid my-auto gap-6"
          )}
          >
            {data?.map?.((item: any) => (
              <div
                key={item?._id}
                className="rounded-lg bg-white p-2 flex space-x-4"
              >
                <div>
                  <div
                    className={classNames(
                      item?.bgColor,
                      "rounded-md flex items-center justify-center p-2.5"
                    )}
                  >
                    <span
                      className="text-lg text-white"
                      aria-hidden="true"
                    ></span>
                  </div>
                </div>
                <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
                  <div>
                    <dt className="truncate text-xs text-gray-500">
                      {item?.label}
                    </dt>
                    <dd className="flex items-baseline justify-between">
                      <p className="text-2xl font-normal text-gray-900">
                        {numeral(item?.value).format("0,0")}
                      </p>
                      <p className="text-gray-600 ml-2 flex items-baseline text-xs font-semibold">
                       
                        {numeral((item?.value / total) * 100).format("0,0.00")}%
                      </p>
                    </dd>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={classNames(
            data?.length > 5 ? "w-[28rem] h-[28rem] mx-auto" : "w-80 h-80",
          )}
          >
            <ResponsivePie
              data={data}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              innerRadius={0}
              padAngle={0.7}
              cornerRadius={5}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              colors={{ datum: "data.color" }}
              enableArcLinkLabels={false}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={"white"}
            />
          </div>
        </div>
        <div className="rounded-b-lg bg-gray-50 p-4  sm:px-6 flex">
          <div className="flex-1 flex flex-row justify-between">
            <dt className="text-xl text-gray-900 font-normal">
              Total Customers
            </dt>
            <dd className="text-xl text-gray-900 font-semibold">
              {numeral(total).format("0,0")}
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
