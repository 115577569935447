import { gql } from "@apollo/client";

export const GET_SERVICE_ORDER_EXPORTS = gql`
    query GetServiceOrderExports(
        $page: Int
        $pageSize: Int
        $sort: String
        $search: String
        $searchFields: [String!]
        $fromDate: Date
        $toDate: Date
        $region: ID
        $district: ID
        $type: ServiceOrderExportType
        $types: [ServiceOrderExportType]
        $status: ServiceOrderExportStatus
        $statuses: [ServiceOrderExportStatus]
        $createdBy: ID
        $createdByType: ServiceOrderExportActors
        $origin: Application
    ) {
        rows: getServiceOrderExports(
            page: $page
            pageSize: $pageSize
            sort: $sort
            search: $search
            searchFields: $searchFields
            fromDate: $fromDate
            toDate: $toDate
            region: $region
            district: $district
            type: $type
            types: $types
            status: $status
            statuses: $statuses
            createdBy: $createdBy
            createdByType: $createdByType
            origin: $origin
        ) {
            _id
            code
            description
            status
            type
            createdAt
            fileUrl
            district {
                _id
                code
                name
            }
            region {
                _id
                code
                name
            }
            meta {
                exportedCount
                totalDataCount
            }
            startedAt
            completedAt
            updatedAt
        }
        count: getServiceOrderExportsCount(
            search: $search
            searchFields: $searchFields
            fromDate: $fromDate
            toDate: $toDate
            region: $region
            district: $district
            type: $type
            types: $types
            status: $status
            statuses: $statuses
            createdBy: $createdBy
            createdByType: $createdByType
            origin: $origin
        )
    }
`;

export const GET_SERVICE_ORDER_EXPORT = gql`
    query GetServiceOrderExport($id: ID!) {
        serviceOrderExport: getServiceOrderExport(id: $id) {
            _id
            code
            description
            createdBy {
                ... on User {
                    _id
                    code
                    emailAddress
                    firstName
                    gender
                    ghanaCardNumber
                    lastName
                    phoneNumber
                    profileImageUrl
                }
                ... on PartnerUser {
                    _id
                    code
                    emailAddress
                    firstName
                    gender
                    ghanaCardNumber
                    lastName
                    phoneNumber
                    profileImageUrl
                }
                ... on MeterContractorUser {
                    _id
                    code
                    emailAddress
                    firstName
                    gender
                    ghanaCardNumber
                    lastName
                    phoneNumber
                    profileImageUrl
                }
                ... on ContractorUser {
                    _id
                    code
                    emailAddress
                    firstName
                    gender
                    ghanaCardNumber
                    lastName
                    phoneNumber
                    profileImageUrl
                }
            }
            createdAt
            updatedAt
            status
            type
            fileUrl
            startedAt
            completedAt
            region {
                _id
                code
                name
            }
            district {
                _id
                code
                name
            }
            meta {
                exportedCount
                totalDataCount
            }
            params {
                args 
                ctx
            }
        }
    }
`;


export const CANCEL_SERVICE_ORDER_EXPORT = gql`
mutation CancelServiceOrderExport($id: ID!, $reason: String!) {
    cancelServiceOrderExport: cancelServiceOrderExport(id: $id, reason: $reason) {
    _id
  }
}
`