import { gql, useQuery } from "@apollo/client";

const GET_INSTALLATION_SERVICE_ORDERS = gql`
  query GetInstallationServiceOrders(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: InstallationServiceOrderStatus
    $statuses: [InstallationServiceOrderStatus]
    $category: InstallationServiceOrderCategory
    $priority: InstallationServiceOrderPriority
    $assigner: ID
    $approver: ID
    $assignee: ID
    $farmingOutContractor: ID
    $meterContractor: ID
    $fromDate: Date
    $toDate: Date
  ) {
    rows: getInstallationServiceOrders(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      farmingOutContractor: $farmingOutContractor
      meterContractor: $meterContractor
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      meterContractor {
        _id
        code
        name
      }
      farmingOutContractor {
        _id
        code
        name
      }
      assigneeType
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      category
      status
      priority
      estimatedResolutionDate
      servicePoint {
        _id
        code
        geoCode
      }
      servicePointCode
      request {
        _id
        code
        type
        inspection {
          resolution {
            service {
              meterPhase
            }
          }
        }
      }
      installationType {
        _id
        code
        name
      }
      installationMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
      }
      installationMeterSystemCode
      installationMeterSystemSyncStatus
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      createdAt
      updatedAt
    }
    count: getInstallationServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      category: $category
      priority: $priority
      meterContractor: $meterContractor
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;


export const QUEUE_SERVICE_ORDER_EXPORT = gql`
    mutation QueueInstallationServiceOrderExport(
        $dateField: String
        $fromDate: Date
        $toDate: Date 
        $region: ID
        $district: ID
        $assigner: ID
        $approver: ID
        $assignee: ID
        $farmingOutContractor: ID
        $farmingOutContractorUser: ID
        $meterContractor: ID
        $status: InstallationServiceOrderStatus
        $category: InstallationServiceOrderCategory
        $priority: InstallationServiceOrderPriority
        $result: InstallationServiceOrderResult
        $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
        $installationMeterSystemSyncMethod: InstallationMeterSystemSyncMethod
        $description: String
    ) {
        queueInstallationServiceOrderExport(
            dateField: $dateField
            region: $region
            district: $district
            toDate: $toDate
            fromDate: $fromDate
            status: $status
            assignee: $assignee
            assigner: $assigner
            approver: $approver
            farmingOutContractor: $farmingOutContractor
            farmingOutContractorUser: $farmingOutContractorUser
            meterContractor: $meterContractor
            priority: $priority
            category: $category
            result: $result
            installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
            installationMeterSystemSyncMethod: $installationMeterSystemSyncMethod
            description: $description
        )
    }
`;

export const useInstallationServiceOrders = (variables: any) => {
  const { data, ...rest } = useQuery(GET_INSTALLATION_SERVICE_ORDERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const installationServiceOrders = data?.rows || [];
  const count = data?.count || 0;
  return { installationServiceOrders, count, ...rest };
};
