import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface MeterContractorPickerProps {
  filter?: {
    district?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  position?: ("top" | "bottom");
}

const GET_METER_CONTRACTORS = gql`
    query GetMeterContractorsSelect($district: ID) {
        meterContractors: getMeterContractors(
            district: $district
            sort: "name"
            page: 0 pageSize: 0
        ) {
            _id
            code
            name
            contactPerson {
                fullName
                phoneNumber
                emailAddress
            }
        }
    }
`;

export const MeterContractorPicker: FC<MeterContractorPickerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_METER_CONTRACTORS, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      {...form}
      id={id ?? "meterContractor"}
      label={label ?? "Meter Contractor"}
      placeholder='Select Meter Contractor'
      optionsLoading={loading}
      options={(data?.meterContractors ?? [])?.map((meterContractor: any) => ({
        label: {
          title: meterContractor.name as string,
        },
        value: rawId ? meterContractor._id : meterContractor,
      }))}
    />
  );
};

export default MeterContractorPicker;
