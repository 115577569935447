import { FC, useEffect } from "react";
import {wrapClick, getExportStatusDateField} from "utils";
import {useReactiveVar, useMutation, DocumentNode} from "@apollo/client";
import { useFormik } from "formik";
import _ from "lodash";
import toast from "react-hot-toast";
import { Modal } from "../../layouts";
import {SearchSelectInput, RadioGroupInput, TextInput} from "components/core";
import {DistrictPicker, RegionPicker, ContractorPicker, MeterContractorPicker} from "containers";
import {
  CustomPicker,
  DayPicker,
  QuarterPicker,
  MonthPicker,
  WeekPicker,
} from "components/layouts";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { currentUserVar } from 'apollo/cache/auth';
import lodash from "lodash";


const defaultOrderStatuses = [
  {
    value: "",
    label: "All Orders",
  },
  {
    value: "Pending",
    label: "Pending Orders",
  },

  {
    value: "Assigned",
    label: "Assigned Orders",
  },
  {
    value: "InProgress",
    label: "In Progress Orders",
  },
  {
    value: "Resolved",
    label: "Resolved Orders",
  },
  {
    value: "Disapproved",
    label: "Disapproved Orders",
  },
  {
    value: "Completed",
    label: "Completed Orders",
  },
  {
    value: "Cancelled",
    label: "Cancelled Orders",
  },
];



const validServiceOrderTypes = [
  'BacklogInstallationServiceOrder',
  'BoundaryInstallationServiceOrder',
  'BoundaryReplacementServiceOrder',
  'DisconnectionServiceOrder',
  'DisconnectionMonitoringServiceOrder',
  'IllegalityConfirmationServiceOrder',
  'InspectionServiceOrder',
  'InstallationServiceOrder',
  'InstallationMonitoringServiceOrder',
  'InvestigationServiceOrder',
  'FaultServiceOrder',
  'MeterRemovalServiceOrder',
  'PrepaidMonitoringServiceOrder',
  'ReconnectionServiceOrder',
  'RegularizationInspectionServiceOrder',
  'RegularizationInstallationServiceOrder',
  'ReplacementServiceOrder',
  'SuspectedIllegality',
  'TransformerInstallationServiceOrder',
  'TransformerMaintenanceServiceOrder',
  'TransformerReplacementServiceOrder',
] as const;
type ValidServiceOrderType = typeof validServiceOrderTypes[number];

const defaultViews = ["all-time", "day", "week", "month", "custom"] as const;
type ViewType = typeof defaultViews[number];

type ServiceOrderOptions = {
  invoiceTypes?: string[];
}

interface ServiceOrderExportFormProps {
  initialValues?: Record<string, any>;
  views?: ViewType[]
  open: boolean;
  setOpen: (value: boolean) => void;
  filter: Record<string, any>;
  orderStatuses?: {label: string, value: string}[];
  title?: string;
  serviceOrderType: ValidServiceOrderType;
  QUEUE_EXPORT_MUTATION: DocumentNode;
  opts?: ServiceOrderOptions
}

export const ServiceOrderExportForm: FC<ServiceOrderExportFormProps> = ({
  open,
  setOpen,
  filter,
  orderStatuses = defaultOrderStatuses,
  initialValues = {},
  views = defaultViews,
  QUEUE_EXPORT_MUTATION,
  serviceOrderType,
  title,
  opts,
}) => {

  const searchParams = useSearch<LocationGenerics>();
  const currentUser = useReactiveVar(currentUserVar);

  const exportForm = useFormik({
    initialValues: {
      status: "",
      periodType: searchParams?.view || "all-time",
      region: currentUser?.region?._id || "",
      district: currentUser?.district?._id || "",
      sourceRegion: currentUser?.region?._id || "",
      sourceDistrict: currentUser?.district?._id || "",
      destinationRegion: "",
      destinationDistrict: "",
      fromDate: "",
      toDate: "",
      description: "",
      ...filter,
      ...initialValues,
    },
    onSubmit: (values) => {
      queueServiceOrderExport({
        variables: {
          ...sanitizeValues(values),
          dateField: getExportStatusDateField((values.status)),
          ...(values.periodType === 'all-time' ? { fromDate: undefined, toDate: undefined } : {})
        },
        fetchPolicy: "no-cache",
      }).then(({ data }) => {
        toast(
          JSON.stringify({
            type: "success",
            title: "Export queued successfully",
          })
        );
        setOpen(false)
      });
    },
  });

  const [queueServiceOrderExport, { loading }] = useMutation(
    QUEUE_EXPORT_MUTATION,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    exportForm.setValues({
      status: "",
      periodType: searchParams?.view || "all-time",
      region: currentUser?.region?._id || "",
      district: currentUser?.district?._id || "",
      sourceRegion: currentUser?.region?._id || "",
      sourceDistrict: currentUser?.district?._id || "",
      destinationRegion: "",
      destinationDistrict: "",
      fromDate: "",
      toDate: "",
      description: "",
      ...filter
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  function isOrderType(orderTypes: ValidServiceOrderType[]){
    return orderTypes.includes(serviceOrderType)
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`${ title || ( serviceOrderType ? `Export ${_.startCase(serviceOrderType + 's')}` : "Export Data" ) }`}
      description='Indicate the export details below'
      size={"5xl"}
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(exportForm.handleSubmit)}
          >
            {loading
              ? "Exporting data..."
              : "Export Data"}
          </button>
        </>
      )}
    >
      <div>
        <RadioGroupInput
          id='status'
          options={[
            {
              label: "All Orders",
              value: "",
            },
            ...orderStatuses,
          ]}
          label={"Select a Status"}
          placeholder='Select Status'
          {...exportForm}
        />
      </div>
      <div className='border-b border-white/10 mt-8'>
        <h2 className='text-base font-semibold leading-6 text-gray-900'>
          Select filters to apply
        </h2>
        <p className='mt-1 text-sm leading-6 text-gray-600'>
          Select filters to filter down the data exported
        </p>

        <div className='mt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6'>
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'DisconnectionServiceOrder',
              'DisconnectionMonitoringServiceOrder',
              'IllegalityConfirmationServiceOrder',
              'InspectionServiceOrder',
              'InstallationServiceOrder',
              'InstallationMonitoringServiceOrder',
              'InvestigationServiceOrder',
              'FaultServiceOrder',
              'MeterRemovalServiceOrder',
              'PrepaidMonitoringServiceOrder',
              'ReconnectionServiceOrder',
              'RegularizationInspectionServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'SuspectedIllegality',
              'TransformerInstallationServiceOrder',
              'TransformerMaintenanceServiceOrder',
              'TransformerReplacementServiceOrder',
            ]) ? (!currentUser?.region && (
              <div className='sm:col-span-2'>
                <RegionPicker id='region' rawId={true} {...exportForm} />
              </div>
            )) : null
          }
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'DisconnectionServiceOrder',
              'DisconnectionMonitoringServiceOrder',
              'IllegalityConfirmationServiceOrder',
              'InspectionServiceOrder',
              'InstallationServiceOrder',
              'InstallationMonitoringServiceOrder',
              'InvestigationServiceOrder',
              'FaultServiceOrder',
              'MeterRemovalServiceOrder',
              'PrepaidMonitoringServiceOrder',
              'ReconnectionServiceOrder',
              'RegularizationInspectionServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'SuspectedIllegality',
              'TransformerInstallationServiceOrder',
              'TransformerMaintenanceServiceOrder',
              'TransformerReplacementServiceOrder',
            ]) ? (exportForm.values.region && (
              <div className='sm:col-span-2'>
                <DistrictPicker id='district' rawId={true} filter={{region: exportForm.values.region}} {...exportForm} />
              </div>
            )) : null
          }

          {
            isOrderType([
              'BoundaryInstallationServiceOrder',
              'BoundaryInstallationServiceOrder',
            ]) ? (!currentUser?.region && (
              <>
                <div className="sm:col-span-2">
                  <RegionPicker
                    id="sourceRegion"
                    label="Source Region"
                    rawId={true}
                    {...exportForm}
                  />
                </div>
                {exportForm.values.sourceRegion && (
                  <div className="sm:col-span-2">
                    <DistrictPicker
                      id="sourceDistrict"
                      label="Source District"
                      rawId={true}
                      filter={{region: exportForm.values.sourceRegion}}
                      {...exportForm}
                    />
                  </div>
                )}
                <div className="sm:col-span-2 sm:col-start-1">
                  <RegionPicker
                    id="destinationRegion"
                    label="Destination Region"
                    rawId={true}
                    {...exportForm}
                  />
                </div>
                {exportForm.values.destinationRegion && (
                  <div className="sm:col-span-2">
                    <DistrictPicker
                      id="destinationDistrict"
                      label=""
                      rawId={true}
                      filter={{region: exportForm.values.destinationRegion}}
                      {...exportForm}
                    />
                  </div>
                )}
              </>
            )) : null
          }
          {
            isOrderType([
              'DisconnectionServiceOrder',
              'ReconnectionServiceOrder',
            ]) && (
              <div className="sm:col-span-2 sm:col-start-1">
                <SearchSelectInput
                  id='category'
                  options={["CustomerRequest", "NonPayment", "NonTrace"].map(category => ({
                      label: { title: _.startCase(category) },
                      value: category
                    })
                  )}
                  label={"Category"}
                  placeholder='Select Category'
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'InstallationMonitoringServiceOrder',
            ]) && (
              <div className="sm:col-span-2 sm:col-start-1">
                <SearchSelectInput
                  id="type"
                  options={[
                    "InstallationServiceOrder",
                    "ReplacementServiceOrder",
                    "BacklogInstallationServiceOrder",
                    "RegularizationInstallationServiceOrder",
                  ].map(type => ({
                      label: { title: _.startCase(type) },
                      value: type,
                    }),
                  )}
                  label={"Service Order Type"}
                  placeholder="Select Type"
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'IllegalityConfirmationServiceOrder',
            ]) && (
              <div className="sm:col-span-2 sm:col-start-1">
                <SearchSelectInput
                  id="category"
                  options={["ExistingCustomer", "ProspectiveCustomer", "NonProspectiveCustomer"].map(category => ({
                      label: { title: _.startCase(category) },
                      value: category,
                    }),
                  )}
                  label={"Category"}
                  placeholder="Select Category"
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'InspectionServiceOrder',
              'InstallationServiceOrder',
              'InvestigationServiceOrder',
              'ReplacementServiceOrder',
            ]) && (
              <div className="sm:col-span-2 sm:col-start-1">
                <SearchSelectInput
                  id="category"
                  options={[
                    {label: {title: "Express"}, value: "Express"},
                    {label: {title: "Premium"}, value: "Premium"},
                    {label: {title: "Standard"}, value: "Standard"},
                  ]}
                  label={"Category"}
                  placeholder="Select Category"
                  {...exportForm}
                  position="top"
                />
              </div>
            )
          }
          {
            isOrderType(['SuspectedIllegality']) && (
              <div className="sm:col-span-2 sm:col-start-1">
                <SearchSelectInput
                  id="origin"
                  options={["ConsumptionAnalysis", "TipOff", "AnonymousTipOff", "FieldOperations"].map((origin) => ({
                    label: { title: _.startCase(origin) },
                    value: origin,
                  }))}
                  label={"Origin"}
                  placeholder="Select Origin"
                  position="top"
                  {...exportForm}
                />
              </div>
            )
          }

          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'BoundaryInstallationServiceOrder',
              'BoundaryReplacementServiceOrder',
              'DisconnectionServiceOrder',
              'DisconnectionMonitoringServiceOrder',
              'InspectionServiceOrder',
              'InstallationServiceOrder',
              'InstallationMonitoringServiceOrder',
              'InvestigationServiceOrder',
              'IllegalityConfirmationServiceOrder',
              'FaultServiceOrder',
              'PrepaidMonitoringServiceOrder',
              'MeterRemovalServiceOrder',
              'ReconnectionServiceOrder',
              'RegularizationInspectionServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'TransformerInstallationServiceOrder',
              'TransformerMaintenanceServiceOrder',
              'TransformerReplacementServiceOrder',
            ]) && (
              <div className="sm:col-span-2">
                <SearchSelectInput
                  id="priority"
                  options={[
                    {label: { title: "Low" }, value: "Low"},
                    {label: { title: "Medium" }, value: "Medium"},
                    {label: { title: "High" }, value: "High"},
                    {label: { title: "Critical" }, value: "Critical"},
                  ]}
                  label={"Priority"}
                  placeholder='Select Priority'
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'DisconnectionServiceOrder',
              'DisconnectionMonitoringServiceOrder',
              'InspectionServiceOrder',
              'InstallationServiceOrder',
              'InstallationMonitoringServiceOrder',
              'InvestigationServiceOrder',
              'FaultServiceOrder',
              'MeterRemovalServiceOrder',
              'PrepaidMonitoringServiceOrder',
              'ReconnectionServiceOrder',
              'RegularizationInspectionServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'PrepaidMonitoringServiceOrder',
              'TransformerMaintenanceServiceOrder',
            ]) && (
              <div className='sm:col-span-2'>
                <SearchSelectInput
                  id='result'
                  options={[
                    {label: { title: "Passed" }, value: "Passed"},
                    {label: { title: "Failed" }, value: "Failed"},
                  ]}
                  label={"Result"}
                  placeholder='Select Result'
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'IllegalityConfirmationServiceOrder',
            ]) && (
              <div className='sm:col-span-2'>
                <SearchSelectInput
                  id='result'
                  options={["IllegalityConfirmed", "NonIllegality"].map(result => ({
                      label: { title: _.startCase(result) },
                      value: result
                    })
                  )}
                  label={"Result"}
                  placeholder='Select Result'
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'ReplacementServiceOrder'
            ]) && (
              <div className="sm:col-span-2">
                <SearchSelectInput
                  id="replacementType"
                  options={[
                    "PostpaidToPrepaid",
                    "PostpaidToPostpaid",
                    "PostpaidToAMR",
                    "PrepaidToPrepaid",
                    "PrepaidToPostpaid",
                    "PrepaidToAMR",
                    "AMRToPrepaid",
                    "AMRToPostpaid",
                    "AMRToAMR",
                    "NoMeterToPrepaid",
                    "NoMeterToPostpaid",
                    "NoMeterToAMR",
                  ].map((replacementType) => ({
                    label: { title: lodash.startCase(replacementType) },
                    value: replacementType,
                  }))}
                  label={"Replacement Type"}
                  placeholder="Select Replacement Type"
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'InspectionServiceOrder',
            ]) && (
              <div className="sm:col-span-2">
                <SearchSelectInput
                  id="serviceClass"
                  options={[
                    {label: { title: "Residential" }, value: "Residential"},
                    {label: { title: "Non-Residential" }, value: "NonResidential"},
                    {label: { title: "Industrial" }, value: "Industrial"},
                    {label: { title: "Special Load Tariff" }, value: "SpecialLoadTariff"},
                  ]}
                  label={"Service Class"}
                  placeholder="Select Service Class"
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'InspectionServiceOrder',
            ]) && (
              <div className="sm:col-span-2">
                <SearchSelectInput
                  id="serviceType"
                  options={[
                    {label: { title: "Postpaid" }, value: "Postpaid"},
                    {label: { title: "Prepaid" }, value: "Prepaid"},
                    {label: { title: "AMR" }, value: "AMR"},
                  ]}
                  label={"Service Type"}
                  placeholder="Select Service Type"
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'BoundaryInstallationServiceOrder',
              'BoundaryReplacementServiceOrder',
              'InstallationServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'TransformerInstallationServiceOrder',
              'TransformerReplacementServiceOrder',
            ]) && (
              <div className="sm:col-span-2">
                <SearchSelectInput
                  id="installationMeterSystemSyncStatus"
                  options={[
                    {label: {title: "Pending"}, value: "Pending"},
                    {label: {title: "Attempted"}, value: "Attempted"},
                    {
                      label: {title: "Forward Synced"},
                      value: "ForwardSynced",
                    },
                    {
                      label: {title: "Reverse Synced"},
                      value: "ReverseSynced",
                    },
                    {label: {title: "Completed"}, value: "Completed"},
                  ]}
                  label={"Sync Status"}
                  placeholder="Select Sync Status"
                  {...exportForm}
                  position="top"
                />
              </div>
            )
          }
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'BoundaryInstallationServiceOrder',
              'BoundaryReplacementServiceOrder',
              'DisconnectionServiceOrder',
              'DisconnectionMonitoringServiceOrder',
              'ReconnectionServiceOrder',
              'InspectionServiceOrder',
              'InstallationServiceOrder',
              'InvestigationServiceOrder',
              'FaultServiceOrder',
              'RegularizationInspectionServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'PrepaidMonitoringServiceOrder',
              'MeterRemovalServiceOrder',
              'TransformerInstallationServiceOrder',
              'TransformerMaintenanceServiceOrder',
              'TransformerReplacementServiceOrder',
            ]) && (
              <div className="sm:col-span-2 col-start-1">
                <SearchSelectInput
                  id="assigner"
                  options={[
                    {label: { title: "Assigned By Me" }, value: currentUser?._id ?? ""},
                  ]}
                  label={"Assigner"}
                  placeholder="Select Assigner"
                  {...exportForm}
                />
              </div>
            )
          }
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'BoundaryInstallationServiceOrder',
              'BoundaryReplacementServiceOrder',
              'InstallationServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'TransformerInstallationServiceOrder',
              'TransformerReplacementServiceOrder',
            ]) && (
              <div className="sm:col-span-2 col-start-1">
                <ContractorPicker
                  id="farmingOutContractor"
                  label={"Contractor"}
                  placeholder="Select Contractor"
                  filter={{district: exportForm?.values?.district}}
                  {...exportForm}
                  rawId={true}
                />
              </div>
            )
          }
          {
            isOrderType([
              'BacklogInstallationServiceOrder',
              'BoundaryInstallationServiceOrder',
              'BoundaryReplacementServiceOrder',
              'InstallationServiceOrder',
              'RegularizationInstallationServiceOrder',
              'ReplacementServiceOrder',
              'TransformerInstallationServiceOrder',
              'TransformerReplacementServiceOrder',
            ]) && (
              <div className="sm:col-span-2">
                <MeterContractorPicker
                  id="meterContractor"
                  label={"Meter Contractor"}
                  placeholder="Select Meter Contractor"
                  filter={{district: exportForm?.values?.district}}
                  {...exportForm}
                  rawId={true}
                />
              </div>
            )
          }


          <div className="sm:col-span-2 sm:col-start-1">
            <SearchSelectInput
              id="periodType"
              options={views.map((view) => ({
                label: {title: _.upperFirst(view)},
                value: view,
              }))}
              label={"Period Type"}
              placeholder='Select Period Type'
              {...exportForm}
              position='top'
            />
          </div>
          {exportForm?.values?.periodType &&
            exportForm?.values?.periodType !== "all-time" && (
              <div className='sm:col-span-2'>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Period
                </label>
                <div className='mt-1'>
                  {exportForm?.values?.periodType === "day" && (
                    <DayPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "week" && (
                    <WeekPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "month" && (
                    <MonthPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "quarter" && (
                    <QuarterPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                  {exportForm?.values?.periodType === "custom" && (
                    <CustomPicker
                      fromState={[
                        exportForm.values.fromDate,
                        (value) => {
                          exportForm.setFieldValue("fromDate", value);
                        },
                      ]}
                      toState={[
                        exportForm.values.toDate,
                        (value) => {
                          exportForm.setFieldValue("toDate", value);
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            )}
          <div className='sm:col-span-2 sm:col-start-1'>
            <TextInput
              id='description'
              label="Description"
              placeholder='A short description...'
              {...exportForm}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};


function sanitizeValues(values: any) {
  return lodash.chain(values)
    .entries()
    .map(([key, value]) => [key, Boolean(value) ? value : undefined])
    .fromPairs()
    .value()
}

export default ServiceOrderExportForm;
