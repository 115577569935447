import { useQuery } from "@apollo/client";
import { ServiceOrderExportView } from "components/cards";
import { Modal } from "components/layouts";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useDownloadFile, wrapClick } from "utils";
import { GET_SERVICE_ORDER_EXPORT } from "./apollo";

export default function ViewServiceOrderExportContainer({
  open,
  setOpen,
  refetch
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_ORDER_EXPORT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const { downloadAction } = useDownloadFile({
    onEnd: () => {
      toast(JSON.stringify({
        type: "success",
        title: "File downloaded successfully",
      }));
    },
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred while downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + `${data?.serviceOrderExport?.type ?? ""} Service Order Export.xlsx`,
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Service Order Export Information"
      hidePadding
      size="4xl"
      description="Details of the service order export are shown below"
      renderActions={() => (
        <>
          {data?.serviceOrderExport?.fileUrl && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(() => {
                downloadAction(data?.serviceOrderExport?.fileUrl);
              })}
            >
              Download Export
            </button>
          )}
        </>
      )}
    >
      {data?.serviceOrderExport?._id && (
        <ServiceOrderExportView serviceOrderExport={data?.serviceOrderExport} />
      )}
    </Modal>
  );
}
