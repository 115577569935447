
export function getExportStatusDateField(status: string): string {

  if(!status) return 'createdAt'

  if(["Resolved", "Disapproved", "Cancelled", "Completed"].includes(status)){
    return  status[0].toLowerCase() + status.substring(1) + 'At'
  }

  return 'createdAt'


}