import { useReactiveVar } from "@apollo/client";
import { OfficeHeader, Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  useTableData,
} from "utils";
import config from "config";
import ViewInstallationServiceOrderContainer from "./view";
import { Avatar, SearchSelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import lodash from "lodash";
import { useInstallationServiceOrders, QUEUE_SERVICE_ORDER_EXPORT } from "./hooks";
import { ServiceOrderExportForm } from 'components/forms'

const DATE_FORMAT = "YYYY-MM-DD";

const orderStatuses = [
  {
    label: { title: "Pending Assignment" },
    value: "Pending",
  },
  {
    label: { title: "Assigned To Supplier" },
    value: "AssignedToSupplier",
  },
  {
    label: { title: "Assigned To Installer" },
    value: "AssignedToInstaller",
  },
  { label: { title: "In Progress" }, value: "InProgress" },
  {
    label: { title: "Resolved By Installer" },
    value: "Resolved",
  },
]

const InstallationServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [status, setStatus] = useUrlState("status");

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const revokeAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">, val: boolean) =>
    () => {
      // alert(JSON.stringify({ action, val }))
      setModal(val ? action : undefined);
      navigate({
        search: (old) => ({
          ...old,
          id: val ? old?.id : undefined,
        }),
      });
    };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "serviceRequestCode",
        "servicePointCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
      ],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
      ...(searchParams?.status
        ? {
            status: searchParams?.status,
          }
        : {
            statuses: [
              "Pending",
              "AssignedToSupplier",
              "AssignedToInstaller",
              "InProgress",
              "Resolved",
            ],
          }),
    }),
    [searchParams]
  );

  const { installationServiceOrders, count, loading, networkStatus, refetch } =
    useInstallationServiceOrders(filter);

  const records = useTableData({ rows: installationServiceOrders, count });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"service orders"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ServiceOrderExportForm
                serviceOrderType={"InstallationServiceOrder"}
                orderStatuses={orderStatuses.map(
                  (status) => ({
                    label: status.label.title,
                    value: status.value,
                  })
                )}
                open={exportOpen}
                setOpen={setExportOpen}
                filter={filter}
                QUEUE_EXPORT_MUTATION={QUEUE_SERVICE_ORDER_EXPORT}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
                <SearchSelectInput
                  id="status"
                  labelHidden={true}
                  options={orderStatuses}
                  label={""}
                  placeholder="Select Status"
                  setFieldValue={(_: any, value: string) =>
                    value ? setStatus(value) : setStatus(undefined)
                  }
                  values={{ status }}
                  setFieldTouched={() => {}}
                  setFieldError={() => {}}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Location
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  ServicePoint
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Assigned | ETA
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Assigner
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Assignee
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.location?.address || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.location?.community || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {lodash.startCase(item?.request?.type) || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.request?.inspection?.resolution?.service
                      ?.meterPhase || "N/A"}{" "}
                    Phase
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.servicePoint?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.servicePoint?.geoCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.category === "Premium"
                        ? `bg-primary-100 text-primary-800`
                        : "",
                      item?.category === "Standard"
                        ? `bg-gray-100 text-gray-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.category || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.assignedAt
                      ? moment(item?.assignedAt).format(dateFormat)
                      : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.estimatedResolutionDate
                      ? moment(item?.estimatedResolutionDate).format(dateFormat)
                      : "N/A"}
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.assigner?.lastName || "")?.trim(),
                            (item?.assigner?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.assigner?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {[
                          (item?.assigner?.lastName || "")?.trim(),
                          (item?.assigner?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.assigner?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 ">
                  {item.assigneeType === "MeterManufacturingContractor" ? (
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <Avatar
                          disabled={true}
                          alt={item?.meterContractor?.name || "N A"}
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-gray-900 dark:text-gray-100">
                          {item?.meterContractor?.name || "N/A"}
                        </div>
                        <div className="text-gray-500 dark:text-gray-400">
                          Meter Manufacturing Contractor
                        </div>
                      </div>
                    </div>
                  ) : item.assigneeType === "FarmingOutContractor" ? (
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <Avatar
                          disabled={true}
                          alt={item?.farmingOutContractor?.name || "N A"}
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-gray-900 dark:text-gray-100">
                          {item?.farmingOutContractor?.name || "N/A"}
                        </div>
                        <div className="text-gray-500 dark:text-gray-400">
                          Farming Out Contractor
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <Avatar
                          disabled={true}
                          alt={
                            [
                              (item?.assignee?.lastName || "")?.trim(),
                              (item?.assignee?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"
                          }
                          src={item?.assignee?.profileImageUrl || ""}
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-gray-900 dark:text-gray-100">
                          {[
                            (item?.assignee?.lastName || "")?.trim(),
                            (item?.assignee?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"}
                        </div>
                        <div className="text-gray-500 dark:text-gray-400">
                          {item?.assignee?.phoneNumber || "N/A"}
                        </div>
                      </div>
                    </div>
                  )}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 ">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.status === "Acknowledged"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      item?.status === "Expired"
                        ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                        : "",
                      item?.status === "Failed"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      item?.status === "Posted"
                        ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                        : "",
                      item?.status === "Pending"
                        ? `bg-[#eec96b5a] text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      item?.status === "Success"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      item?.status === "Reversed"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.status
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.status || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewInstallationServiceOrderContainer
            open={modal === "view"}
            setOpen={(val: boolean) => revokeAction("view", val)()}
          />
        </>
      )}
    </main>
  );
};

export default InstallationServiceOrdersPage;
