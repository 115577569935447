import { FC } from "react";
import { CalendarHeader } from "../calendar-header";

interface OfficeHeaderProps {
  renderActions?: FC<{}>;
  views?: string[];
  defaultView?: string
}

export const OfficeCalendarHeader: FC<OfficeHeaderProps> = ({ renderActions, views, defaultView }) => {

  return (
    <CalendarHeader
      renderActions={renderActions}
      views={views}
      defaultView={defaultView}
    />
  );
};
