import { useMutation, useQuery } from "@apollo/client";
import { LocationGenerics } from "router/location";
import {wrapClick} from "utils";
import { CancelServiceOrderForm } from "components/forms";
import { Modal } from "components/layouts";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { CANCEL_SERVICE_ORDER_EXPORT, GET_SERVICE_ORDER_EXPORT } from "./apollo";
import lodash from "lodash";


export default function CancelServiceOrderExportsContainer({
 open,
 setOpen,
 refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_ORDER_EXPORT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [cancelServiceOrderExport] = useMutation(CANCEL_SERVICE_ORDER_EXPORT);

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    onSubmit: async (values) => {
      await cancelServiceOrderExport({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.cancelServiceOrderExport._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Export Cancelled Successfully",
            })
          );
          form.resetForm();
          refetch?.()
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not cancel Service Order Exports",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`Cancel ${lodash.startCase(data?.serviceOrderExport?.type)} Export`}
      description={`Provide reasons for cancelling the ${lodash.startCase(data?.serviceOrderExport?.type)} Export`}
      loading={loading}
      renderActions={() => (
        ["Pending", "InProgress"].includes(data?.serviceOrderExport?.status) ? (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting
              ? "Cancelling Service Order Export ..."
              : "Cancel Service Order Export"}
          </button>
        </>
      ) : <div></div>)}
    >
      {data?.serviceOrderExport?._id && <CancelServiceOrderForm form={form} />}
    </Modal>
  );
}
